import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({ onChange }) => {
    const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
    console.log(siteKey)
    const captchaStyles = {
        marginTop: '40px',
        width: '304px',
    };
    return (
        <ReCAPTCHA
            sitekey={siteKey}
            onChange={onChange}
            style={captchaStyles}
        />
    );
};

export default Captcha;
